<template>
  <div class="container tufont turegularfontsize" style="display: flex; padding-top:0.3vw">
    <div
      class="col-md-4 offset-md-1 text-start"
      style="padding-bottom: 0vw; padding-top: 0vw; outline: 0.00em dashed red"
    >
      {{this.title}}:
    </div>
    <div
      class="col-md-7 text-start"
      style="padding-bottom: 0vw; padding-top: 0vw; outline: 0.00em dashed red"
    >
      <b>{{ this.orderItem }}</b>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderItem",
  props: {
    title: String,
    orderItem: String,
  },
};
</script> 
   
           
           
